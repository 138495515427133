{
  "name": "sat-oilandgas-web",
  "version": "1.0.16",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "production": "ng build --configuration production",
    "staging": "ng build --configuration staging",
    "development": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test": "npx playwright test",
    "test:e2e": "ng serve & npx playwright test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.4",
    "@angular/cdk": "^18.0.4",
    "@angular/common": "^18.0.4",
    "@angular/compiler": "^18.0.4",
    "@angular/core": "^18.0.4",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.0.4",
    "@angular/platform-browser": "^18.0.4",
    "@angular/platform-browser-dynamic": "^18.0.4",
    "@angular/router": "^18.0.4",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/free-brands-svg-icons": "^6.5.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@fullcalendar/angular": "^6.1.14",
    "@fullcalendar/core": "^6.1.14",
    "@fullcalendar/daygrid": "^6.1.14",
    "@fullcalendar/interaction": "^6.1.14",
    "@fullcalendar/timegrid": "^6.1.14",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.12.0",
    "@tinymce/tinymce-angular": "^8.0.0",
    "chart.js": "^4.4.3",
    "primeflex": "^3.3.1",
    "primeicons": "7.0.0",
    "primeng": "17.18.1",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "tinymce": "^7.2.0",
    "tslib": "^2.5.0",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.5",
    "@angular/cli": "^18.0.5",
    "@angular/compiler-cli": "^18.0.4",
    "@playwright/test": "^1.45.0",
    "@types/jasmine": "~4.3.1",
    "@types/node": "^20.12.8",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.5"
  }
}